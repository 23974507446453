/**
 * Creates a canonical URL by removing unnecessary query parameters
 * @param url - The original URL
 * @param keepParams - Optional array of parameter names to keep
 * @returns A clean canonical URL
 */

const prepareUrl = (url: string, keepParams: string[] = []): string => {
  try {
    // Handle empty or invalid URLs
    if (!url || typeof url !== "string") return "";

    // For relative URLs, prepend with origin
    const isRelative = !url.startsWith("http");
    const baseUrl = typeof window !== "undefined" ? window.location.origin : "";
    const fullUrl = isRelative
      ? `${baseUrl}${url.startsWith("/") ? "" : "/"}${url}`
      : url;

    // Parse the URL
    const urlObject = new URL(fullUrl);

    // If no params to keep, return without query string
    if (!keepParams.length) {
      return `${urlObject.origin}${urlObject.pathname}`;
    }

    // Create a new URLSearchParams object to manipulate parameters
    const params = new URLSearchParams();

    // Only keep specified parameters
    keepParams.forEach((param) => {
      const value = urlObject.searchParams.get(param);
      if (value) {
        params.set(param, value);
      }
    });

    // Build the canonical URL
    const queryString = params.toString();

    return `${urlObject.origin}${urlObject.pathname}${
      queryString ? `?${queryString}` : ""
    }`;
  } catch (error) {
    console.error("Error creating canonical URL:", error);

    // Fallback method
    try {
      const questionMarkIndex = url.indexOf("?");
      if (questionMarkIndex === -1 || !keepParams.length) {
        // No query params or nothing to keep
        return questionMarkIndex === -1
          ? url
          : url.substring(0, questionMarkIndex);
      }

      // Manual parsing for fallback
      const base = url.substring(0, questionMarkIndex);
      const query = url.substring(questionMarkIndex + 1);
      const pairs = query.split("&");
      const filteredPairs = pairs.filter((pair) => {
        const key = pair.split("=")[0];
        return keepParams.includes(key);
      });

      return filteredPairs.length > 0
        ? `${base}?${filteredPairs.join("&")}`
        : base;
    } catch {
      return url; // Return original if all else fails
    }
  }
};
const createCanonicalUrl = (url: string, keepParams: string[] = []): string => {
  const urlresolve = prepareUrl(url, keepParams);
  // console.log(urlresolve);
  return urlresolve;
};

export default createCanonicalUrl;
