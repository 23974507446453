import { useRouter } from "next/router";
import React from "react";
import useGetPersistParams from "../../custom-hooks/useGetPersintParamas.hook";

const MyContext = React.createContext<INewsContextValues>({
  showNews: false,
  onToggle: () => {},
  isCloseForUser: false,
  setCloseForUser: () => {},
});

interface INewsContext {
  children: React.ReactNode;
}

interface INewsContextValues {
  showNews: boolean;
  onToggle: (manualShow?: boolean) => void;
  isCloseForUser: boolean;
  setCloseForUser: any;
}

function NewsContext(props: INewsContext) {
  const [showNews, setShownNews] = React.useState(false);
  const [isCloseForUser, setCloseForUser] = React.useState(false);

  const onToggle = (manualShow?: boolean) => {
    if (manualShow == undefined) return setShownNews(!showNews);
    setShownNews(manualShow);
  };

  const values = {
    showNews,
    onToggle,
    isCloseForUser,
    setCloseForUser,
  };

  return (
    <MyContext.Provider value={values}>{props.children}</MyContext.Provider>
  );
}

export default NewsContext;

export function useShowNews() {
  const context = React.useContext(MyContext);
  return context;
}
