import { Grid, MenuItem } from "@mui/material";
import { Form, Formik, FormikFormProps, FormikProps } from "formik";
import { useTranslations } from "next-intl";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { userUpdateProfile } from "api/clients/clients.request";
import { useAppDispatch, useAppSelector } from "custom-hooks/redux.hooks";
import { array, boolean, object, string } from "yup";
import Button from "shared/components/Button";
import SelectField from "shared/components/select-field";
import CheckboxCustom from "shared/components/checkBox";
import LabelField from "shared/components/label-field";
import { logout, setUser } from "store/feature/auth/auth.slice";
import { closeAuthDialog } from "store/feature/auth/auth-dialog.slice";
import styled from "@emotion/styled";
import { CPagesPath } from "shared/models/const/pages-path";
import { useRouter } from "next/router";
import { css } from "@emotion/css";
import useEventGa from "shared/hooks/useEventGa.hook";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";
import useDataLayerEvent from "custom-hooks/data-layer-event.hook";

export default function CompleteSignUpForm() {
  const { event } = useDataLayerEvent();
 const refererValue: any = useGetPersistParams({ onlyCode: true }); 
  const user = useAppSelector(({ Auth }) => Auth.user);
  const [types, setTypes] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const t = useTranslations("CompleteSignUpDialog.form");
  const tError = useTranslations("error");
  const tShared = useTranslations("shared");
  const userTypes = ["Comprador", "Propietario", "Agente"];
  const router = useRouter();
  const initialValues = {
    full_name: user?.full_name,
    type: "",
    is_subscribed: true,
    email: user?.email,
  };

  const validationSchema = object({
    full_name: string(),
    type: array().min(1, tError("field-length-min-one")).required(tError("required")),
    is_subscribed: boolean(),
    email: string()
  });

  const { mutate, isLoading, mutateAsync } = useMutation(userUpdateProfile, {
    onSuccess: (user) => {
      dispatch(setUser(user));
      dispatch(closeAuthDialog());
      router.push(CPagesPath.es.profile);
    },
  });

  const cancel = () => {
    dispatch(logout());
    dispatch(closeAuthDialog());
  };

  const onSubmit = (values: any) => {
    mutateAsync(values).then(() => { 
      event({
        event_name:
        "sign_up",
        body:{
          tipo_usuario:values.type,
          codigo_referido: refererValue.areferer ?? refererValue.referer ?? null,
          refererForAgent: refererValue.referer ? true : false,
          refererForAgency: refererValue.areferer ? true : false,
        }
      });
    })
    
  };

  const SelectType = (
    type: string,
    fh: FormikProps<{
      full_name: string | undefined;
      type: string;
      is_subscribed: boolean;
      email: string | undefined;
    }>
  ) => {
    if (types.includes(type)) {
      fh.setFieldValue(
        "type",
        types.filter((item) => item !== type)
      );
      setTypes(types.filter((item) => item !== type));
    } else {
      fh.setFieldValue("type", [...types, type]);
      setTypes([...types, type]);
    }
  };

  const cards: any = [
    {
      msg: t("field.cards.buyer.description"),
      type: t("field.cards.buyer.title"),
      value: userTypes[0],
      isActive: types.includes(userTypes[0]),
    },
    {
      msg: t("field.cards.owner.description"),
      type: t("field.cards.owner.title"),
      value: userTypes[1],
      isActive: types.includes(userTypes[1]),
    },
    {
      msg: t("field.cards.agent.description"),
      type: t("field.cards.agent.title"),
      value: userTypes[2],
      isActive: types.includes(userTypes[2]),
    },
  ];
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} margin="auto">
                <GridCardContent>
                  {cards.map((card: any, i: number) => (
                    <div
                      key={i}
                      onClick={() => SelectType(card.value, formikProps)}
                      className={card.isActive ? "active" : ""}
                    >
                      <h3>{card.type}</h3>
                      <p>{card.msg}</p>
                    </div>
                  ))}
                </GridCardContent>
              </Grid>
              <Error>{formikProps.errors.type}</Error>
              <Grid
                item
                xs={12}
                sm={10}
                margin="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                  justifyContent: "center",
                }}
              >
                <CheckboxCustom
                  onChange={() => {
                    formikProps.setFieldValue(
                      "is_subscribed",
                      !formikProps.values.is_subscribed
                    );
                  }}
                  checked={!!formikProps.values.is_subscribed}
                  name="is_subscribed"
                  style={{
                    display: "inline-block",
                    justifyContent: "flex-start",
                    padding: "5px 0px 9px 9px",
                  }}
                />
                <ContentLabel>
                  <LabelField
                    label={t("field.is_subscribed")}
                    positionLabel="left"
                  />
                </ContentLabel>
              </Grid>
              <Grid item xs={12} textAlign="center" mb={2}>
                <FormButtons>
                  <Button
                    fs="12px"
                    bg="transparent"
                    variant="text"
                    thin_button
                    onClick={cancel}
                  >
                    {tShared("dialog.cancelBtn")}
                  </Button>
                  <Button
                    type="submit"
                    fs="12px"
                    bg="--color-secondary"
                    thin_button
                    disabled={isLoading}
                    loadingMessage={
                      isLoading ? tShared("forms.sendingButton") : null
                    }
                  >
                    {tShared("forms.submitButton")}
                  </Button>
                </FormButtons>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

const FormButtons = styled.section`
  display: flex;
  justify-content: space-evenly;
`;

const Error = styled.span`
  color: red;
  font-size: 11px;
  width: 100%;
  text-align: center;
  // background-color: red;
  margin-left: 25px;
  margin-top: 10px;
`;

const ContentLabel = styled.div`
  & .label-customs {
    font-size: 13px;
  }
`;

const GridCardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc(80% / 3));
  margin: 0px auto;
  column-gap: 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, calc(80% / 1));
    row-gap: 10px;
  }
  div {
    // background-color: red;
    height: 130px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    border: 1.5px solid #ccc;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    :hover {
      cursor: pointer;
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
    &.active {
      background-color: red;
      color: #fff;
      font-weight: 500;
    }
    p {
      text-align: center;
      padding: 0px 10px;
      font-size: 13px;
    }
  }
`;
