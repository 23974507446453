import React from "react";

function useDataLayerEvent() {
  const event = (info: IEvent) => {
    const Widows: any = window;
    if (typeof Widows.dataLayer != 'undefined' && typeof Widows != 'undefined') {
      Widows.dataLayer.push({
        event: info.event_name,
        ...info,
      });
      console.log("dataLayer event", info);
    }
  };

  return { event };
}

export default useDataLayerEvent;
interface IEvent {
  event_name: string;
  body: any;
}
